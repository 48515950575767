import { Modal, Table } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { parse } from 'query-string';
import Loader from '../../../common/components/Loader'
import { getProjectSummaryAction } from './logic'
import { getProjectsDataAction, getProjectsDataResetAction } from '../../container/MyProjects/logic'

const ProjectSummaryView = ({ data }) => {
  const dispatch = useDispatch()
  const projectSummaryData = useSelector((store) => store.projectSummaryData)
  const projectsData = useSelector((store) => store.projectsData)
  const [projectSummaryDataState, setProjectSummaryDataState] = useState([])
  const [projectDataState, setProjectDataState] = useState([])
  const [allianceData, setAllianceData] = useState([])
  const [tierData, setTierData] = useState([])
  const [versionsData, setVersionsData] = useState([])
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const [statusData, setStatusData] = useState([])
  const [priorityData, setPriorityData] = useState([])
  const [originatingStatusData, setOriginatingStatusData] = useState([])
  const [lcorStatusData, setLcorStatusData] = useState([])
  const [complienceData, setComplienceData] = useState([])
  const [reviewDetails, setReviewDetailsData] = useState([])
  const [reviewSummaryData, setReviewSummaryData] = useState([])

  useEffect(() => {
    dispatch(getProjectSummaryAction({
      project_id: parse(location.search).project_id,
    }))
  }, [])

  useEffect(() => {
    dispatch(getProjectsDataResetAction())
    dispatch(getProjectsDataAction({
      project_id: parse(location.search).project_id,
    }));
  }, []);

  useEffect(() => {
    if (projectSummaryData.flag) {
      setAllianceData(projectSummaryData?.data?.data?.alliance_data)
      setTierData(projectSummaryData?.data?.data?.tier_data)
      setVersionsData(projectSummaryData?.data?.data?.versions)
      setComplienceData(projectSummaryData?.data?.data?.complience)
      setProjectSummaryDataState([projectSummaryData?.data?.data])
      setStatusData(projectSummaryData?.data?.data?.status)
      setPriorityData(projectSummaryData?.data?.data?.priority)
      setOriginatingStatusData(projectSummaryData?.data?.data?.originating_party)
      setLcorStatusData(projectSummaryData?.data?.data?.lcor_status)
      setReviewDetailsData(projectSummaryData?.data?.data?.review_details)
      setReviewSummaryData(projectSummaryData?.data?.data?.review_summary)
    }
  }, [JSON.stringify(projectSummaryData)])

  useEffect(() => {
    if (projectsData.flag) {
      setProjectDataState(projectsData?.data?.data)
    }
  }, [JSON.stringify(projectsData)])

  const columns = [
    {
      title: 'Country Type',
      dataIndex: 'country_type',
      width: '10%',
      key: 'country_type',
      render: (_, record) => (
        record.country_type ? record.country_type : '-'
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      width: '15%',
      key: 'region',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: '15%',
      key: 'country',
    },
    {
      title: 'Sites in scenario',
      dataIndex: 'sites_in_scenario',
      width: '12%',
      key: 'sites_in_scenario',
      render: (_, record) => (
        record.sites_in_scenario ? record.sites_in_scenario : '-'
      ),
    },
    {
      title: 'Average patients',
      dataIndex: 'average_patients_or_sites',
      width: '12%',
      key: 'average_patients_or_sites',
      render: (_, record) => (
        record.average_patients_or_sites ? record.average_patients_or_sites : '-'
      ),
    },
    {
      title: 'SFL target',
      dataIndex: 'sfl_target_sites',
      width: '12%',
      key: 'sfl_target_sites',
      render: (_, record) => (
        record.sfl_target_sites ? record.sfl_target_sites : '-'
      ),
    },
    {
      title: 'CFL target',
      dataIndex: 'cfl_target',
      width: '12%',
      key: 'cfl_target',
      render: (_, record) => (
        record.cfl_target ? record.cfl_target : '-'
      ),
    },
    {
      title: 'Site outreach',
      dataIndex: 'site_outreach',
      width: '12%',
      key: 'site_outreach',
      render: (_, record) => (
        record.site_outreach ? record.site_outreach : '-'
      ),
    },
    {
      title: 'Site recommended for bidding',
      dataIndex: 'site_recommended_for_bidding',
      width: '12%',
      key: 'site_recommended_for_bidding',
      render: (_, record) => (
        record.site_recommended_for_bidding ? record.site_recommended_for_bidding : '-'
      ),
    },
  ];

  const projectAndResultSummaryCol = [
    {
      title: '# Analysis',
      dataIndex: 'analysisCount',
      key: 'analysisCount',
    },
    {
      title: '# Queries',
      dataIndex: 'queryCount',
      key: 'queryCount',
    },
    {
      title: '# Unique PIs',
      dataIndex: 'pis',
      key: 'pis',
    },
    {
      title: '# Unique Centers',
      dataIndex: 'centers',
      key: 'centers',
    },
  ]

  const AllianceTypeCol = [
    {
      title: 'Alliance Type',
      dataIndex: 'alliance_type',
      key: 'alliance_type',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const status = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const priority = [
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const review_detail = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'review_completed',
      key: 'review_completed',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'review_completed',
      render: (country) => <div>{country.length ? country.join(',') : '-'}</div>,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => <div>{text.split('T')[0]}</div>,
    },
    {
      title: 'Action Not Taken',
      dataIndex: 'action_not_taken',
      key: 'action_not_taken',
      // render: (text) => <div>{text.split('T')[0]}</div>,
    },
    {
      title: 'Recommended',
      dataIndex: 'recommended',
      key: 'recommended',
      // render: (text) => <div>{text.split('T')[0]}</div>,
    },
    {
      title: 'Not Recommended',
      dataIndex: 'not_recommended',
      key: 'not_recommended',
      // render: (text) => <div>{text.split('T')[0]}</div>,
    },
    {
      title: 'Recommended By Lcor',
      dataIndex: 'recommended_by_lcor',
      key: 'recommended_by_lcor',
      // render: (text) => <div>{text.split('T')[0]}</div>,
    },
    {
      title: 'Highly Recommended',
      dataIndex: 'highly_recommended',
      key: 'highly_recommended',
      // render: (text) => <div>{text.split('T')[0]}</div>,
    },
  ]

  const lcor_status = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const originating_party = [
    {
      title: 'Originating Party',
      dataIndex: 'originating_party',
      key: 'originating_party',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const ComplianceCol = [
    {
      title: 'Compliance',
      dataIndex: 'complience',
      key: 'complience',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const TierWiseDataPiCol = [
    {
      title: 'Tier',
      dataIndex: 'tier',
      key: 'tier',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const VersionWiseDataPiCol = [
    {
      title: 'Version',
      dataIndex: 'versions',
      key: 'versions',
    },
    {
      title: '# PIs',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  const downloadBtnClicked = () => {
    const input = document.getElementById('summary-main');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        // eslint-disable-next-line new-cap
        const doc = new jsPDF('pt', 'mm');
        let position = 0;
        doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
          heightLeft -= pageHeight;
        }
        doc.save(`${parse(location.search).project_name}_summary`);
      })
  }
  return (
    <>
      {/* <div className="close-btn-modal" role="presentation" onClick={() => closeModal({ isOpen: false })} /> */}
      <div
        className="download-btn-modal"
        role="presentation"
        onClick={() => {
          setDownloadModalOpen(true)
          downloadBtnClicked()
        }}
      />
      <div className="projectview-main" id="summary-main">
        <Loader loading={projectsData.loading} error={projectsData.error}>
          {projectDataState.length !== 0 && (
            <div className="projectview-table-main">

              <div className="projectview-table-main-layout">
                <div className="projectview-header">
                  Project Details
                </div>
                <div className="projectview-table">
                  {projectDataState.map((itm) => {
                    return (
                      <>
                        <div className="project-deatils-modal-layout">
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Project # </div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.project_number}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Name</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.name}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Pre-award</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.pre_award}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Indications</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.indications.length ? itm.indications.join(', ') : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Analysis Count</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.analysis_count ? itm.analysis_count : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">CDA Type</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.cda_type ? itm.cda_type : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Current Status</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.current_status ? itm.current_status : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Description</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.description ? itm.description : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Feasibility Purpose History</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.feasibility_purpose_history ? itm.feasibility_purpose_history : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Protocol Sponsor Code</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.protocol_sponsor_code_name ? itm.protocol_sponsor_code_name : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Protocol Sponsor Title</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.protocol_sponsor_title ? itm.protocol_sponsor_title : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Segment</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.segment ? itm.segment : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Mandatory Criteria</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.mandatory_criteria ? itm.mandatory_criteria : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Most Preferred Criteria</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.most_preferred_criteria ? itm.most_preferred_criteria : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Aspects To Avoid</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.aspects_to_avoid ? itm.aspects_to_avoid : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Site Identification Strategy</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.site_identification_strategy ? itm.site_identification_strategy : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Pre-Qualification Prioritization Rankings</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.pre_qualification_prioritization_rankings.length && itm.pre_qualification_prioritization_rankings.map((a) => {
                              return (
                                <div>
                                  <div>{a.rank} {a.definition && `- ${a.definition}`}</div>
                                </div>
                              )
                            })}
                            </div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Sponosor site list review required?</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.sponosor_site_list_review_required ? itm.sponosor_site_list_review_required : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Country and Site Targets</div>
                            <div className="project-deatils-modal-layout-card-desc">{itm.country_and_site_targets ? itm.country_and_site_targets : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title"> Save ID </div>
                            <div className="project-deatils-modal-layout-card-desc">{projectSummaryDataState.length && projectSummaryDataState[0]?.save_id ? projectSummaryDataState[0]?.save_id : '-'}</div>
                          </div>
                          <div className="project-deatils-modal-layout-card">
                            <div className="project-deatils-modal-layout-card-title">Save Date</div>
                            <div className="project-deatils-modal-layout-card-desc">{projectSummaryDataState.length && projectSummaryDataState[0]?.last_sucessfull_save_date ? moment(projectSummaryDataState[0]?.last_sucessfull_save_date).format('YYYY-MM-DD') : '-'}</div>
                          </div>
                        </div>
                        <div className="project-deatils-target-table">
                          <Table dataSource={itm?.country_and_site_targets_table} columns={columns} pagination={false} />
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </Loader>
        {data?.view
        && (
          <Loader loading={projectSummaryData.loading} error={projectSummaryData.error}>
            <div className="project-summary-content">
              <div className="projectview-header">
                Project & Results Summary
              </div>
              <div className="project-summary-content-data">
                <div className="projectview-table">
                  <Table
                    columns={projectAndResultSummaryCol}
                    dataSource={projectSummaryDataState}
                    size="medium"
                    pagination={false}
                  />
                </div>
                {allianceData?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      Alliance Type
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={AllianceTypeCol}
                        dataSource={allianceData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}
                {tierData?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      Tier Wise Split Of PIs
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={TierWiseDataPiCol}
                        dataSource={tierData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}
                {versionsData?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      Version Wise Split Of PIs
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={VersionWiseDataPiCol}
                        dataSource={versionsData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}
                {complienceData?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      PIs With Compliance
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={ComplianceCol}
                        dataSource={complienceData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}

                {status?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      Feasibility Status
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={status}
                        dataSource={statusData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}

                {priority?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      Priority
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={priority}
                        dataSource={priorityData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}

                {lcor_status?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      LCOR status
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={lcor_status}
                        dataSource={lcorStatusData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}

                {originating_party?.length !== 0 && (
                  <div className="projectview-table-main table-two-value">
                    <div className="projectview-header">
                      Originating Party
                    </div>
                    <div className="projectview-table">
                      <Table
                        columns={originating_party}
                        dataSource={originatingStatusData}
                        size="medium"
                        pagination={false}
                      />
                    </div>
                  </div>
                )}
                {projectSummaryDataState?.length !== 0 && (
                  <div className="projectview-table-main projectview-table-main-comments">
                    <div className="projectview-header">
                      Comments
                    </div>
                    <div className="projectview-table">
                      <div className="projectview-table-comment-sec">
                        <div className="projectview-table-col-title">
                          # PIs with comments
                        </div>
                        <div className="projectview-table-col-value">
                          {projectSummaryDataState[0]?.pis_with_comments}
                        </div>
                      </div>
                      <div className="projectview-table-comment-sec">
                        <div className="projectview-table-col-title">
                          # Centers with comments
                        </div>
                        <div className="projectview-table-col-value">
                          {projectSummaryDataState[0]?.center_with_comments}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="review-summary">
                  <div className="review-summary-title"> Review Summary </div>
                  <div className="review-summary-content">
                    <div className="review-summary-content-value"> <div className="review-summary-content-value-title"> # Reviewers/Shared Users : </div><div className="review-summary-content-value-desc">{reviewSummaryData.total}</div></div>
                    <div className="review-summary-content-value"> <div className="review-summary-content-value-title"> Reviewed : </div><div className="review-summary-content-value-desc">{reviewSummaryData.reviewed}</div></div>
                    <div className="review-summary-content-value"> <div className="review-summary-content-value-title"> Not Reviewed : </div><div className="review-summary-content-value-desc">{reviewSummaryData.not_reviewed}</div></div>
                  </div>
                </div>
                <div>
                  {reviewDetails?.length !== 0 && (
                    <div className="projectview-table-main">
                      <div className="projectview-header">
                        Review Details
                      </div>
                      <div className="projectview-table">
                        <Table
                          columns={review_detail}
                          dataSource={reviewDetails}
                          size="medium"
                          pagination={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Loader>
        )}
      </div>
      {
          downloadModalOpen && <Modal title="Download Confirmation" open={downloadModalOpen} onCancel={() => setDownloadModalOpen(false)} onOk={() => setDownloadModalOpen(false)}> <div className="error-list-item"> Your file will be downloaded shortly </div> </Modal>
        }
    </>
  )
}

export default ProjectSummaryView
